@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  @apply bg-slate-200;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hero {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/src/images/wave.svg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

}

.navbar {
  position: sticky;
  top: 0;
}

.c-btn {
  border-color: white;
  transition: all .5s;
}

.c-mdi {
  transition: all .5s;

}

.c-btn:hover {
  background-color: rgb(0, 158, 158);
  border-color: rgb(0, 158, 158);
  
}


.c-btn:hover .c-mdi {
  transform: rotate(90deg);
}


.skills-animation {
  animation: wipe-enter 2s ease;
}

@keyframes wipe-enter {
  from {
    width: 0%;
  }

}

.title-animation {
  animation: slide-in 1s ease;
}

@keyframes slide-in {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.title-animation {
  animation: slide-in 1s ease;
}


.slide-img:hover{
  filter:opacity(30%);
}

.slide-img {
  transition: all .5s;
}

.description {
  display: none;
  transition: all .5s;
}

.slide-img:hover + .description {
  position:absolute;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
  transform: translateX(-50%);
}

li a:hover {
  filter: opacity(50%);
}


input, textarea {
  width: 33.333%;
}

@media screen and (max-width: 600px) {


  input, textarea {
    width: 95%;
  }


}
